.reactor-main-vote{
    width: 100%;
    height: 100vmin;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.reactor-nav-vote{
    color: var(--color-second);
    margin-bottom: 2rem;
    margin-left: 8rem;
}

.container-vote{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}