.elemPersoVote{
    list-style: none;
    border: 4px solid var(--color-princ);
    border-radius: 20px;
    background-color: var(--color-ters);
    position:  relative;
}

.elemPersoVote::after{
    content: '';
    background-color: var(--color-second);
    border: 4px solid var(--color-princ);
    border-radius: 20px;
    min-width: 100%;
    min-height: 100%;
    padding: .2rem;
    position: absolute;
    z-index: -1;
    top: 0vmin;
    left: 0vmin;
}

.elemPersoVote.voted{
    border: 4px solid var(--color-princ);
    border-radius: 20px;
    background-color: var(--color-ters);
    position:  relative;
    z-index: 10;
}

.card-container {
    transition: transform .2s ease;
}

.card-container.voted {
    transform: scale(1.06);
}

.persoVoteImg{
    border-radius: 20px 20px 0px 0px;
    border-bottom: 4px solid var(--color-princ);
}

.persoVoteImg.notVoted{
    filter: grayscale(1);
    transition: all .5s ease-in-out;
}

.persoVoteInfo{
    padding: .8rem;
}

.btnVotePerso{
    border: 4px solid var(--color-second);
    background-color: var(--color-ters);
    padding: .5rem 2rem;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    margin-top: .5rem;
}

.btnVotePerso:hover{
    background-color: var(--color-second);
    color: var(--color-ters);
    transition: all .4s ease-in-out;
}

.btnVotePerso.voted{
    background-color: var(--color-second);
    color: var(--color-ters);
    transition: all .5s ease-in-out;
}

.btnVotePerso.notVoted {
    background-color: var(--color-middle-grey);
    color: var(--color-quat);
    border-color: var(--color-middle-grey);
    transition: all .5s ease-in-out;
}

.listPersoVote{
    display: flex;
    gap: 2rem;
}