.reactor-connect, .reactor-inscript{
    cursor: pointer;
    font-size: 1vw;
    color: var(--color-princ);
    text-decoration: none;
    padding: .5rem;
}


.reactor-connect:hover, .reactor-inscript:hover{
    color: #BC284A;
}