.info-perso{
    width: 740px;
    border: 4px solid var(--color-princ);
    border-radius: 25px;
    padding: 0 0 2rem 0;
    background-color: var( --color-ters);
    position: relative;
}

.info-perso::after{
    content: '';
    background-color: var(--color-second);
    border: 4px solid var(--color-princ);
    border-radius: 20px;
    min-width: 100%;
    min-height: 100%;
    padding: .2rem;
    position: absolute;
    z-index: -1;
    top: 0vmin;
    left: 0vmin;
    transition: all 0.3s;
}



.info-title{
    text-align: left;
    padding: 1rem 1rem 1rem 3rem;
}

.info-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 3rem;
}

.edit-img{
    width: 23px;
    height: 23px;
    cursor: pointer;
}

.form-info-perso{
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 300px 300px;
    gap: 1.5rem 2.5rem;
}

.form-elem{
    display: flex;
    flex-direction: column;
}

.label-info-perso{
    margin: 0;
}

.input-info-perso{
    border: none;
    background-color: var(--color-light-grey);
    color: var(--color-princ);
    border-radius: 8px;
    font-size: 16px;
    padding: .8rem;
}

.edit-password{
    border: none;
    display: flex;
    align-items: center;
    text-align: left;
    background-color: var(--color-ters);
    color: var(--color-second);
    cursor: pointer;
    font-size: 16px;
}