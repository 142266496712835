.card{
    border: 4px solid var(--color-princ);
    border-radius: 25px;
    background-color:  var(--color-ters);
    display: flex;
    flex-direction: column;
    height: 485px;
}

.card::after{
    content: '';
    background-color: var(--color-second);
    border: 4px solid var(--color-princ);
    border-radius: 25px;
    min-height: 100%;
    min-width: 100%;
    padding: .2rem;
    position: relative;
    z-index: -1;
    bottom: 28.9rem;
    left: 0;
    transition: all 0.3s;
}

.headProfile{
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 2rem 2rem 1rem 2rem;
    text-align: left;
}

.profile-photo{
    max-width: 90px;
    max-height: 90px;
    background-color: var(--color-ters);
    border: 4px solid var(--color-second);
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pp{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem;
    width: 70px;
    height: 70px;
}

.edit-PPimg{
    position: absolute;
    background-color: var(--color-ters);
    padding: 0 .5rem;
    top: 315px;
    cursor: pointer;
}

.text-headProfile{
    margin-left: 1rem;
}

.profile-level{
    color: var(--color-second);
}

.grid-icons{
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 150px 150px;
    gap: 1rem;
    margin: 2rem;
}

.elem-icon{
    border: 4px solid var(--color-middle-grey);
    border-radius: 15px;
    padding: 1rem;
    color: var(--color-middle-grey);
    font-weight: 700;
    cursor: pointer;
}

.elem-icon.selected{
    border-color: var(--color-princ);
    color: var(--color-ters);
    background-color: var(--color-second);
    transition: all .3s ease-in;
}

.profil-component-choice{
    width: 740px;
}