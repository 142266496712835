

.reactor-div-tete-info-detail.Cashor{
    cursor:default;
    position: absolute;
    width: max-content;
    height: auto;
    top: -7%;
    right: 3%;
    background-color: var(--color-ters);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.reactor-div-tete-info-detail.X-Ray{
    cursor:default;
    position: absolute;
    width: max-content;
    height: auto;
    top: -7%;
    right: 3%;
    background-color: var(--color-ters);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.reactor-div-tete-info-detail.Snakor{
    cursor:default;
    position: absolute;
    width: max-content;
    height: auto;
    top: -7%;
    right: 3%;
    background-color: var(--color-dark-purple);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.reactor-div-info-jeu img{
    margin: 0;
}

.reactor-img-info-detail-jeux-head{
    width: 10vmin;
    padding: 1.5vmin;
}

.reactor-div-tete-info-detail.Golden.Cashor::after{
    background-color: var(--color-golden);
    z-index: -1;
}

.reactor-div-tete-info-detail.Golden.Snakor::after{
    background-color: var(--color-light-purple);
    z-index: -1;
}