
.boutique-affiche-div{
    height: 390px;
    width: 270px;
    position: relative;
    transition: transform .3s ease;
}

.boutique-affiche-div-month{
    height: 390px;
    width: 565px;
    position: relative;
    transition: transform .3s ease;
}

.boutique-affiche-div:hover{
    z-index: 10;
    transform: scale(1.25);
}
.boutique-affiche-div-month:hover{
    z-index: 10;
    transform: scale(1.25);
}

.boutique-affiche-div:hover .Boutique-affiche-block-info{
    height: 35%;
}
.boutique-affiche-div-month:hover .Boutique-affiche-block-info{
    height: 35%;
}
.boutique-affiche-div:hover .Boutique-affiche-button-achete{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.boutique-affiche-div-month:hover .Boutique-affiche-button-achete{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.boutique-affiche-box{
    border: 4px solid var(--color-princ);
    border-radius: 20px;
    position: absolute;
    width: 100%;
    height: 100%;
}


.boutique-affiche-box .Boutique-affiche-skin{
    margin-top: 1vmin;
    height: 80%;
    width: auto;
    position: relative;
}
.boutique-affiche-box::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0.3vmin;
    top: 0.3vmin;
    z-index: -10;
    border: 4px solid var(--color-princ);
    border-radius: 20px;
    background-color: var(--after-color, white);
}

.Boutique-affiche-block-skin{
    width: 170px;
    height: 235px;
    margin: 1rem;
}

.lock-image{
    position: absolute;
    right: 43%;
    top: 35%;
    z-index: 1;
}

.Boutique-affiche-block-info{
    width: 100%;
    min-height: 25%;
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top: 4px solid var(--color-princ);
    background-color: var(--color-ters);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: .5rem;
}

.Boutique-affiche-block-info-month{
    width: 100%;
    min-height: 25%;
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top: 4px solid var(--color-princ);
    background-color: var(--color-ters);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    margin-top: .5rem; 
}

.Boutique-affiche-info-nom{
    font-weight: 900;
    font-size: 18px;
    margin-top: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Boutique-affiche-info-cita{
    font-weight: 200;
    font-size: 14px;
    margin-top: .3rem;
    margin-bottom: .3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Boutique-affiche-prix-div{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    position: absolute;
    bottom: 80%;
    z-index: 1;
    width: 55%;
    font-weight: 900;
}

.boutique-affiche-piece{
    position: relative;
}

.Boutique-affiche-prix{
    background-color: var(--color-ters);
    width: 90%;
    right: 0;
    padding: .3rem;
    border: 4px solid var(--color-princ);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-left: none;
    position: absolute;
    padding-left: 2rem;
    display: flex;
    justify-content: center;
    z-index: -1;
}

.Boutique-affiche-button-achete{
    width: 175px;
    height: 35px;
    padding: .6rem;
    font-weight: 500;
    background-color: var(--color-button-achete);
    color: var(--color-ters);
    border: none;
    border-radius: 12px;
    cursor: pointer;
    position: relative;
    display: none;
    margin-top: .2rem;
}
