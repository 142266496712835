
/* main */

.reactor-main{
  height: 100%;
  padding-bottom: 20rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.reactor-presentation{
  width: 100%;
  height: 35vmin;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding: 15vmax;
  padding-top: 5vmin;
  padding-bottom: 0;
}

.reactor-presentation-div{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.reactor-main-btn-create-perso{
  background-color: var(--color-second);
  border-radius: 1vmin;
  margin-top: 2vmin;
  padding: 1vmin 7vmin 1vmin 7vmin;
  cursor: pointer;
  color: white;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  text-decoration: none;
}
.reactor-main-btn-create-perso:hover{
  background-color: var(--color-princ);
  opacity: 50%;
}

.reactor-div-back{
  width: 40%;
}
.reactor-div-back.active{
  background-color: var(--color-ters);
  border: 0.7vmin solid var(--color-princ);
  box-shadow: 1vmin 1vmin 0px var(--color-princ);
  border-radius: 5vmin;
  z-index: 1;
  overflow: hidden;
}

.reactor-pres-div-video{
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reactor-presentation-video{
  border-radius: 3vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-ters);
  background-color: var(--color-ters);
  font-size: 3vmin;
  position: relative;
  border: 0.7vmin solid var(--color-princ);
}
.reactor-presentation-video iframe{
  border-radius: 20px;
}


.reactor-presentation-video::after{
  content: '';
  background-color: var(--color-second);
  border: 0.7vmin solid var(--color-princ);
  border-radius: 3vmin;
  min-height: 100%;
  min-width: 100%;
  padding: 0.3vmin;
  position: absolute;
  z-index: -1;
  top: 0vmin;
  left: 0vmin;
  transition: all 0.3s;
}


.reactor-presentation-skin-mois-block{
  width: 45%;
  height: 100%;
  position: relative;
}
.reactor-presentation-skin-mois-block-titre{
  width: max-content;
  height: max-content;
  margin-left: 6vmin;
  margin-top: 3vmin;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 3;
  padding-bottom: 1vmin;
}

.reactor-presentation-skin-mois-titre{
  font-weight: 900;
  font-size: 2.3vmin;
}

.reactor-presentation-skin-mois-titre-slogan{
  font-size: 1.5vmin;
}

.reactor-presentation-skin-mois-img-skin{
  width: 100%;
  height: 100%;
  margin-top: 2vmin;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-end;
  position: absolute;
  z-index: 2;
  bottom: 0;
  padding: 3vmin;
}
.reactor-presentation-skin-mois-img-skin img{
  width: 17vmin;
  height: auto;
}

.reactor-presentation-skin-mois-block-skin{
  width: 27vmax;
  height: 70%;
  border: 0.7vmin solid var(--color-princ);
  border-radius: 5vmin;
  position: absolute;
  bottom: -2vmin;
  right: 0;
  background-image: url(../../components/skin-du-mois/back-mois.png);
  background-repeat:no-repeat;
  background-position: center;
  background-size:cover;
}
.reactor-presentation-skin-mois-block-skin::after{
  content: '';
  background-color: var(--color-mois);
  border: 0.7vmin solid var(--color-princ);
  border-radius: 5vmin;
  min-height: 100%;
  min-width: 100%;
  padding: 0.1vmin;
  position: absolute;
  z-index: -1;
  top: 0vmin;
  left: 0vmin;
  transition: all 0.3s;
}

.react-img-ombre{
  position: absolute;
  z-index: -1;
  margin-bottom: -0.3vmin;
}

.reactor-presentation-skin-mois-slogan-skin{
  background-color: var(--color-ters);
  position: absolute;
  top: 100%;
  left: -3%;
  padding: 0.5vmin;
  border: 0.7vmin solid var(--color-princ);
  border-radius: 2vmin;
  font-weight: 900;
  font-size: 3vmin;
}
.reactor-presentation-skin-mois-slogan-skin::after{
  content: '';
  background-color: var(--color-second);
  border: 0.7vmin solid var(--color-princ);
  border-radius: 2vmin;
  min-height: 100%;
  min-width: 100%;
  padding: 0.1vmin;
  position: absolute;
  z-index: -1;
  top: 0vmin;
  left: 0vmin;
  transition: all 0.3s;
}

@media screen and (max-width: 1000px) {
  .reactor-presentation{
    align-items: center;
    width: 70%;
    padding: 3vmax;
  }
  .reactor-main{
    flex-direction: column;
    align-items: center;
  }
  .reactor-div-back.active{
    background-color: transparent!important;
    border: 0vmin!important;
    box-shadow: 0vmin 0vmin 0px!important;
  }
}