.scoreBox {
    border-radius: 20px;
    margin: 0rem 0rem 1rem 0rem;
    padding: .5rem;
    width: 18rem;
    border: 4px solid black;
    text-transform: uppercase;
    background-color: #1D0134;
    color: white;
    position: relative;
}

.scoreBox::after{
    content: '';
    background-color: var(--color-light-purple);
    border: 4px solid var(--color-princ);
    border-radius: 20px;
    min-width: 100%;
    min-height: 100%;
    padding: .2rem;
    position: absolute;
    z-index: -1;
    top: 0vmin;
    left: 0vmin;
    transition: all 0.3s;
}

.score-title{
    margin: 1rem;
}

.nb-points{
    display: flex;
    align-items: center;
    border: 6px solid #281C57;
    border-radius: 12px;
    padding: .5rem;
    text-align: left;
}

.highscore{
    margin: 1rem;
    padding: .5rem 0 .5rem 0;
}