.playButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0rem 0rem 1rem 0rem;
  padding: 10px;
  height: 4rem;
  width: 18rem;
  color: white;
  background-color: #1D0134;
  border: 4px solid black;
  border-radius: 20px;
  font-size: 1.5em;
  font-weight: 900;
  letter-spacing: 5px;
  text-transform: uppercase;
  position: relative;
}
.playButton::after{
  content: '';
  background-color: var(--color-light-purple);
  border: 4px solid var(--color-princ);
  border-radius: 20px;
  min-width: 100%;
  min-height: 100%;
  padding: .2rem;
  position: absolute;
  z-index: -1;
  top: 0vmin;
  left: 0vmin;
  transition: all 0.3s;
}