

.reactor-Jeu.fleche{
    background-color: var(--color-ters);
    padding: 1vmin;
    font-size: 5vmin;
    font-weight: 900;
    margin: 0vmin 0vmin 0vmin 0vmin;
}

.fleche{
    display: flex;
    justify-content: center;
    align-items: center;
}
