/* info detailler jeux */




.reactor-div-info-jeu.reactor-Jeu{
    min-height:  600px;
    cursor: auto;
}

.reactor-div-info-jeu{
    margin-top: 5vmin;
    position: relative;
    width: 100%;
    height: max-content;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 3vmin;
}

.reactor-div-info-jeu img{
    margin-top: 5vmin;
    bottom: 0;
}

.reactor-div-info-jeu-choix{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 3vmin;
    color: var(--color-ters);
}

.reactor-div-info-jeu-choix .reactor-info-jeu-choix-titre{
    font-weight: 900;
    font-size: 5.5vmin;
}


.blocktetediv{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

