.div-popup{
    background-color: var(--color-second);
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 25%;
    left: 25%;
    max-width: 815px;
    border-radius: 25px;
    border: 4px solid var(--color-princ);
    z-index: 1;
    opacity: 0;
    animation:  popIn  1s forwards;
    animation-delay: .3s;
}

.popup{
    z-index: 1;
    border: 4px solid var(--color-princ);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    padding: 1rem;
    max-width: 815px;
    background-color: var(--color-ters);
    bottom: 9px;
    right: 9px;
    position: relative;
}

.popupOpen{
    animation: popIn .5s forwards;
    height: 100%;
    background-size: 100%;
}


@keyframes popIn {
    0%{opacity: 0;}
    100%{opacity: 1;}
}

@keyframes popOut {
    0%{opacity: 1;}
    100%{opacity: 0;}
}

.popup-img{
    background-color: var(--color-light-grey);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
}

.popup-info{
    text-align: left;
    padding: 1rem;
}

.popupInfo-title{
    padding-bottom: .5rem;
}

.entitled{
    font-weight: 300;
}

.popup-text{
    padding-top: 1rem;
    font-size: 14px;
}

.cross-img{
    display: flex;
    align-items: start;
    max-width: 18px;
    max-height: 18px;
    cursor: pointer;
}