.reactor-btn-jeux{
    max-width: 20vmin;
    min-width: 20vmin;
    width: 100%;
    max-height: 24vmin;
    min-height: 23vmin;
    height: 100%;
    margin: 0vmin 6.5vmin;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}



.divImg-card-game{
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-card-game.X-Ray{
    padding-bottom: .8rem;
}

.img-card-game.Cashor{
    padding-bottom: .5rem;
}

.img-card-game.Snakor{
    width: 100%;
    max-height: 97%;
}

.img-card-game.Coming{
    padding-bottom: .5rem;
}

.reactor-jeu-soustitre{
    font-weight: 100;
    font-size: 1vmin;
}


.btn-jeu.reactor-Jeu.Cashor{
    background-color: var(--color-Cashor-face);
}
.btn-jeu.reactor-border-jeux.Cashor::after{
    background-color: var(--color-Cashor);
    transition: .4s ease-in-out;
}
.btn-jeu.reactor-border-jeux.Cashor:hover::after{
    top: -.6vmin;
    left: -.6vmin;
}


.btn-jeu.reactor-Jeu.X-Ray{
    background-color: var(--color-X-Ray-face);
}
.btn-jeu.reactor-border-jeux.X-Ray::after{
    background-color: var(--color-X-Ray);
    transition: .4s ease-in-out;
}
.btn-jeu.reactor-border-jeux.X-Ray:hover::after{
    top: -.6vmin;
    left: -.6vmin;
}


.btn-jeu.reactor-Jeu.Snakor{
    background-color: var(--color-dark-purple);
}
.btn-jeu.reactor-border-jeux.Snakor::after{
    background-color: var(--color-light-purple);
    transition: .4s ease-in-out;
}
.btn-jeu.reactor-border-jeux.Snakor:hover::after{
    top: -.6vmin;
    left: -.6vmin;
}


.btn-jeu.reactor-Jeu.Fast-Memory{
    background-color: var(--color-Fast-Memory-face);
}
.btn-jeu.reactor-border-jeux.Fast-Memory::after{
    background-color: var(--color-Fast-Memory);
    transition: .4s ease-in-out;
}
.btn-jeu.reactor-border-jeux.Memory:hover::after{
    top: -.6vmin;
    left: -.6vmin;
}


.btn-jeu.reactor-Jeu.Coming{
    background-color: var(--color-Coming-face);
}
.btn-jeu.reactor-border-jeux.Coming::after{
    background-color: var(--color-Coming);
    transition: .4s ease-in-out;
}
.btn-jeu.reactor-border-jeux.Coming:hover::after{
    top: -.6vmin;
    left: -.6vmin;
}

