.div-sGame{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 3rem;
}

.snake-game{
    
    max-width: 500px;
    max-height: 500px;
    position: relative;
}

.snake-game::after{
    content: '';
    background-color: var(--color-light-purple);
    border: 4px solid var(--color-princ);
    border-radius: 20px;
    min-width: 100%;
    min-height: 100%;
    padding: .1rem;
    position: absolute;
    z-index: -1;
    top: .5rem;
    left: .5rem;
    transition: all 0.3s;
}

.appleLogo{
    visibility: hidden;
    width: 0;
    height: 0;
}

.canvas-snake {
    border: 4px solid #1D0134;
    border-radius: 20px;
    background-color: #1D0134;
}


.gameOver {
    position: absolute;
    top: 50%;
    right: 110px;
    color: var(--color-ters);
    font-size: 35px;
}