.block-lock-div{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    z-index: 100;
}

.block-lock-div img{
    width: 30px;
}

.lockconnect{
    opacity: 50%;
    cursor: default;
}

.lockconnect .reactor-choice-active{
    opacity: 100%;
    cursor: pointer;
}

.lockconnect:hover{
    transition: all .4s ease-out;
    opacity: 100%;
}

.lockboutique{
    opacity: 50%;
}
.lockboutique:hover{
    opacity: 100%;
}

