.card-info{
    display: flex;
    background-color: var(--color-ters);
    border: 4px solid var(--color-princ);
    border-radius: 20px;
    padding: .8rem .8rem 0rem .8rem;
    position: relative;
}

.card-info::after{
    content: '';
    background-color: var(--color-second);
    border: 4px solid var(--color-princ);
    border-radius: 20px;
    min-width: 100%;
    min-height: 100%;
    padding: .1rem;
    position: absolute;
    z-index: -1;
    top: 0vmin;
    left: 0vmin;
    transition: all 0.3s;
}

.photo-coins{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-photo{
    background-color: var(--color-light-grey);
    border: 4px solid var(--color-princ);
    border-radius: 20px;
    width: 105px;
    height: 105px;
}

.credits{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 10%;
    width: 46px;
    height: 26px;
    padding-right: .5rem;
}

.div-img-credits{
    position: relative;
    left: 30%;
}

.credits-img{
    width: 23px;
    height: 26px;
}

.credits-number{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--color-princ);
    background-color: var(--color-ters);
    border-radius: 6px;
    padding: 0rem .1rem 0rem 1rem;
}

.card-description{
    text-align: left;
    margin: .5rem;
}

.title-text{
    font-size: 20px;
    font-weight: 500;
}

.text-description{
    padding-top: .5rem;
    font-weight: 200;
}

.grid-triomphes{
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 360px 360px;
    gap: 1rem;
    margin-top: 1rem;
    list-style: none;
}