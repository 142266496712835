
@import "../../variables";

.fastmemory {
    &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    &-example {
        &-head {
            width: fit-content;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;

            border: 1px solid var(--color-princ);
            border-radius: 5px;
        }
    }

    &-items img {
        padding: 10px;
        margin: 10px;
        border: 1px solid var(--color-princ);
        border-radius: 5px;
    }

    &-progress {
        width: 400px;
        height: 30px;
        background: var(--color-princ);
        &-content {
            transition: width .2s ease;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 400px;
            background: var(--color-second);

            p {
                padding-left: 10px;
            }
        }
    }
}