.profil-triomphes{
    display: flex;
    flex-direction: column;
    width: 740px;
}

.div-title-triomphes{
    border: 4px solid var(--color-princ);
    background-color: var(--color-ters);
    border-radius: 20px;
    padding: .8rem;
    text-align: left;
    position: relative;
}

.div-title-triomphes::after{
    content: '';
    background-color: var(--color-second);
    border: 4px solid var(--color-princ);
    border-radius: 20px;
    min-width: 100%;
    min-height: 100%;
    padding: .1rem;
    position: absolute;
    z-index: -1;
    top: 0vmin;
    left: 0vmin;
    transition: all 0.3s;
}