.elem-list-character{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.locked-image{
    max-width: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.card-inventaire{
    width: 740px;
}

.profil-inventory{
    border: 4px solid var(--color-princ);
    background-color: var(--color-ters);
    border-radius: 25px;
    padding: 1rem;
    position: relative;
}

.profil-inventory::after{
    content: '';
    background-color: var(--color-second);
    border: 4px solid var(--color-princ);
    border-radius: 25px;
    min-width: 100%;
    min-height: 100%;
    padding: .2rem;
    position: absolute;
    z-index: -1;
    top: 0vmin;
    left: 0vmin;
    transition: all 0.3s;
}

.inventory-title{
    text-align: left;
    padding-bottom: 2rem;
}

.grid-persos{
    padding: 1rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 3rem 3rem;
    list-style: none;
    max-width: 740px;
    max-height: 740px;
    overflow: hidden;
}

.character{
    display: flex;
    flex: initial;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    max-width: 60px;
}


