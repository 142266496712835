.reactor-block-snakor{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.info-page-game{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: calc(2vw + 10vmin);
    padding-bottom: 1rem;
}

.game{
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-result{
    border-radius: 20px;
    height: 500px;
}

.points-board{
    background-color: #1D0134;
    border: 4px solid black;
    border-radius: 20px;
    padding: .5rem;
    position: relative;
}

.points-board::after{
    content: '';
    background-color: var(--color-light-purple);
    border: 4px solid var(--color-princ);
    border-radius: 20px;
    min-width: 100%;
    min-height: 100%;
    padding: .2rem;
    position: absolute;
    z-index: -1;
    top: 0vmin;
    left: 0vmin;
    transition: all 0.3s;
}

.list-pointsBoard{
    list-style: none;
}

.elem-pointsBoard{
    display: flex;
    color: white;
    padding: .5rem 0 .5rem 0;
}

.nb-coins{

}

.coins-img{
    width: 17px;
    height: 20px;
    display: flex;
    justify-content: right;
}

.deco-elements{
    height: 500px;
    
}

.radar{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18rem;
    height: 14rem;
    background-color: #1D0134;
    border: 4px solid black;
    border-radius: 12px;
    margin-bottom: 1rem;
    position: relative;
}

.radar::after{
    content: '';
    background-color: var(--color-light-purple);
    border: 4px solid var(--color-princ);
    border-radius: 12px;
    min-width: 100%;
    min-height: 100%;
    padding: .2rem;
    position: absolute;
    z-index: -1;
    top: 0vmin;
    left: 0vmin;
    transition: all 0.3s;
}

.radar-img{
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.grid-radar{
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin: 0;
    width: 18rem;
    height: 14rem;
}

.bar-deco{
    width: 18rem;
    height: 4rem;
    background-color: #1D0134;
    border: 4px solid black;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
}

.bar-deco::after{
    content: '';
    background-color: var(--color-light-purple);
    border: 4px solid var(--color-princ);
    border-radius: 12px;
    min-width: 100%;
    min-height: 100%;
    padding: .2rem;
    position: absolute;
    z-index: -1;
    top: 0vmin;
    left: 0vmin;
    transition: all 0.3s;
}

.bar-light{
    align-items: center;
    justify-content: center;
}

.perso-img{
    position: relative;
    right: 40%;
    top: 5%;
    height: 240px;
    width: 268px;
}