.reactor-main-b{
    height: 100%;
    width: 100%;
}

.main-boutique{
    width: 85%;
}

.reactor-main-boutique{
    width: 100%;
    height: 100vmin;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}


.reactor-nav-boutique{
    color: var(--color-second);
    margin-bottom: 4rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.boutique-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.reactor-container-boutique {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3rem;
}

.card-list-boutique{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}