@import "../../../variables.scss";

.mascotpicker-container {
    border: 4px solid var(--color-princ);
    border-radius: 25px;
    background-color: var(--color-ters);
    display: flex;
    flex-direction: column;
    width: 740px;
    position: relative;

    
    .skin-view-choice{
        width: 740px;
    }

    .mascotpicker-item-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -100px;
    }

    .mascotpicker-item {
        $size: .9;
        $scale-hover: 1.03;
        transition: scale .2s ease;

        &.head {
            height: calc(1460px / 5 * $size);
            transform: translateY(20%);
            transform-origin: bottom;
            z-index: 3;
        }

        &.body {
            z-index: 2;
            height: calc(1307px / 5 * $size);
        }

        &.foot {
            z-index: 2;
            height: calc(1043px / 5 * $size);
            transform-origin: top;
            transform: translate(-12%, -40%);
        }

        &:hover {
            scale: $scale-hover;
            .button-close {
                opacity: 0;
            }
        }
    }

    .buttons-choice-mascot{
        display: flex;
        gap: .5rem;
        padding-left: 1rem;
    }

    .choice-button{
        border-top: 4px solid var(--color-middle-grey);
        border-right: 4px solid var(--color-middle-grey);
        border-left: 4px solid var(--color-middle-grey);
        border-bottom: none;
        border-radius: 10px 10px 0px 0px;
        padding: .5rem;
        background-color: var(--color-ters);
        color: var(--color-middle-grey);
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .8rem;
        cursor: pointer;
    }

    .choice-button.activeButton{
        background-color: var(--color-second);
        border-top: 4px solid var(--color-princ);
        border-right: 4px solid var(--color-princ);
        border-left: 4px solid var(--color-princ);
        color: var(--color-ters);
    }

    .choice-button img{
        
    }

    .mascotpicker-preview {
        display: flex;
        margin: 0 auto;
        padding-left: 1rem;
        padding-right: 1rem;
        transition: scale 0.5s ease;
        border-top: 4px solid var(--color-princ);
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 180px;
        width: 740px;

        .mascotpicker-preview-container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 3rem;
            align-items: center;
            margin: 1rem;
            overflow: hidden;

            .mascotpicker-preview-item {
                display: flex;
                padding: 0 20px;
                max-width: 110px;
                cursor: pointer;

                &:hover {
                    scale: 1.05;
                }
            }
        }
    }
    .arrow-skin{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .5rem;
        position: relative;
        cursor: pointer;
    }

}
.mascotpicker-container::before{
        content: '';
        background-color: var(--color-second);
        border: 4px solid var(--color-princ);
        border-radius: 20px;
        min-width: 100%;
        min-height: 100%;
        padding: .2rem;
        position: absolute;
        z-index: -10;
        top: 0vmin;
        left: 0vmin;
        transition: all 0.3s;
    }
@media screen and (min-width: 1000px) {
    .mascotpicker-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        top: 0;
    }
    .mascotpicker-container .mascotpicker-preview {
        max-height: min(50vh, 500px);
        border-radius: 0px 0px 25px 25px;
    }
    .mascotpicker-container .mascotpicker-preview .mascotpicker-preview-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: 100%;
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .mascotpicker-container .mascotpicker-preview .mascotpicker-preview-container .mascotpicker-preview-item {
        padding: 20px 0px;
    }
    .mascotpicker-container .mascotpicker-item-container {
        margin-top: 0px;
    }
    .mascotpicker-container .mascotpicker-item.head {
        max-height: 23vh;
        height: 100%;
    }
    .mascotpicker-container .mascotpicker-item.body {
        height: 20vh;
    }
    .mascotpicker-container .mascotpicker-item.foot {
        height: 8vh;
    }
}

@media screen and (max-width: 1000px) {
    .mascotpicker-container{
        z-index: 1;
        background-image:none!important;
        background-position: center;
        background-repeat: no-repeat;
        display: contents;
    }
    .mascotpicker-container .mascotpicker-item.head {
        max-height: 23vh;
        height: 100%;
    }
    .mascotpicker-container .mascotpicker-item.body {
        height: 20vh;
    }
    .mascotpicker-container .mascotpicker-item.foot {
        height: 16vh;
    }
    .mascotpicker-container .mascotpicker-item-container {
        margin-top: -10vmax;
    }
    .mascotpicker-container .mascotpicker-preview{
        bottom: 5vmin;
        max-height: min(50vmin, 500px);
        margin: -5vmin auto;
    }
    .mascotpicker-container .mascotpicker-preview .mascotpicker-preview-container .mascotpicker-preview-item {
        width: 150px;
    }
    
}